<template>
    <router-view/>
</template>

<script>
export default {
    name: "app",
    methods: {
        getUserData() {
            let self = this;
            this.$api.get(`userdata/${self.$store.state.token}`).then(res => {
                if (res.data.success) {
                    self.$store.commit('userData',res.data.data);
                } else {
                    //self.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
    },
    created() {
        this.getUserData();
    }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "assets/scss/base";
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('assets/font/material/icons.woff2') format('woff2');
}
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
}

</style>
