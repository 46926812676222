`<template>
    <div class="home">
        <div class="header flex">
            <div class="logo"></div>
            <div v-if="this.$store.state.token.length" class="btns">
                <router-link class="btn blue" to="/panel/calendar">Панель управления</router-link>
                <router-link class="btn blue" to="/logout">Выйти</router-link>
            </div>
            <div v-else class="btns">
                <router-link class="btn blue" to="/auth">Авторизация</router-link>
            </div>
        </div>
        <h2>Статистика</h2>
        <table class="params">
            <tr>
                <td>Дел завершено</td>
                <td>{{ publicStat.claim_end }}</td>
            </tr>
            <tr>
                <td>Дел в производстве</td>
                <td>{{ publicStat.claim_proc }}</td>
            </tr>
            <tr>
                <td>Средний срок рассмотрения</td>
                <td>{{ publicStat.claim_period_avg }} дней</td>
            </tr>
            <tr>
                <td>Арбитров</td>
                <td>{{ publicStat.arb_count }}</td>
            </tr>
            <tr>
                <td>Исполнителей</td>
                <td>{{ publicStat.impl_count }}</td>
            </tr>
        </table>
        <page-footer/>
    </div>
</template>

<script>
import pageFooter from "../components/footer";
export default {
    name: 'Home',
    components: {pageFooter},
    data() {
        return {
            publicStat: {},
        }
    },
    methods: {
        getPublicStat() {
            this.$api.get(`publicstat`).then(res => {
                if (res.data.success) {
                    this.publicStat = res.data.data;
                }
            })
        }
    },
    created() {
        this.getPublicStat();
    }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
.home {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 1200px) {
        padding: 10px;
    }
    h2 {
      line-height: 30px;
      margin: 15px 0;
    }
    .header {
        padding: 20px 0;align-items: center;
        .logo {background: url("../assets/img/logo.png") no-repeat center / contain;width: 175px;height: 50px;}
        .btns {
            .btn {margin: 0 0 0 .5rem;}
        }
    }
    .footer {
      color:$cl-grey;
      padding-top: calc(100vh - 390px);
    }
}
.params {
    margin: 0 0 50px;
    td {
        padding: 5px;
    }
}
</style>