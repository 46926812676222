<template>
    <div class="footer flex">
        <div class="l"></div>
        <div class="dev">
            Разработано «<a href="https://smartsite.kz/" target="_blank">СмартСайт</a>»
        </div>
    </div>
</template>

<script>
export default {
    name: "pageFooter"
}
</script>

<style scoped>

</style>